<template>
  <CCard body-wrapper>
    <CProgress
      :value="progressBarValue"
      color="success"
      animated
      style="height:5px;"
      class="mt-0 pt-0 mb-3"
      hidden
    />
    <CRow
      align-vertical="center"
      class="justify-content-between"
    >
      <CCol col="auto">
        <CRow>
          <CCol col="auto">
            <h1>Реестры</h1>
          </CCol>
          <CCol col="auto" class="align-self-center">
            <template v-if="filtersVisible">
              <template v-if="filtersSet">
                <CButton
                  color="warning"
                  @click="filtersVisible = !filtersVisible"
                >
                  Фильтр
                </CButton>
              </template>
              <template v-else>
                <CButton
                  color="dark"
                  @click="filtersVisible = !filtersVisible"
                >
                  Фильтр
                </CButton>
              </template>
            </template>
            <template v-else>
              <template v-if="filtersSet">
                <CButton
                  color="warning"
                  variant="outline"
                  @click="filtersVisible = !filtersVisible"
                >
                  Фильтр
                </CButton>
              </template>
              <template v-else>
                <CButton
                  color="dark"
                  variant="outline"
                  @click="filtersVisible = !filtersVisible"
                >
                  Фильтр
                </CButton>
              </template>
            </template>
          </CCol>
          <CCol col="auto" class="align-self-center">
            <template v-if="loadingReestrs">
              <CCol col="auto">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
                <span class="sr-only">"Пожалуйста, подождите...</span>
              </CCol>
            </template>
            <template v-else>
              <CCol col="auto">
                Количество: <span
                class="font-weight-bold"
              >{{ getLocalizedNumber(total.reestrs) }}</span>
              </CCol>
            </template>
          </CCol>
        </CRow>
      </CCol>
      <CCol
        col="auto"
      >
        <CForm
          method="post"
          id="loadingReestrForm"
        >
          <input
            type="file"
            style="display: none"
            ref="fileElem"
            accept="application/vnd.ms-excel*"
            @change="onFilePicked"
            name="reestrFileName"
          />
        </CForm>
        <template v-if="loadingReestr">
          <CButton
            class="filters__filter-button"
            block
            color="primary"
            size="md"
            :title="loadingReestr ? 'Реестр загружается' : ''"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="sr-only">Пожалуйста, подождите...</span>
          </CButton>
        </template>
        <template v-else>
          <CDropdown
            color="primary"
            toggler-text="Добавить реестр"
            class="filters__filter-button"
            size="md"
            :disabled="loadingProgressBar"
            :title="loadingProgressBar ? 'Реестр находится в обработке' : ''"
          >
            <CDropdownItem
              @click="pickFile('sell')"
            >
              Оплаты
            </CDropdownItem>
            <CDropdownItem
              @click="pickFile('sell_refund')"
            >
              Возвраты
            </CDropdownItem>
          </CDropdown>
        </template>
      </CCol>
    </CRow>
    <CForm
      v-show="filtersVisible"
      class="filters mt-3 p-3"
      @submit.prevent="find"
    >
      <CRow align-vertical="start">
        <CCol
          xs="12"
          xl="4"
        >
          <div class="filters__filter">
            <CButton
              class="filters__filter-button"
              color="primary"
              type="submit"
            >
              <template v-if="loadingReestrs">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span class="sr-only">Пожалуйста, подождите...</span>
              </template>
              <template v-else>
                Найти
              </template>
            </CButton>
            <CButton
              class="filters__filter-button ml-2"
              color="secondary"
              @click="clearFilters"
            >
              Очистить
            </CButton>
          </div>
        </CCol>

        <CCol
          xs="12"
          xl="3"
        >
          <div
            class="filters__filter"
            :class="{ 'filters__filter--active': filters.reestr_type !== '' }"
          >
            <CSelect
              label="Тип реестра"
              :options="[ { value: null, label: 'Все' },  { value: 'sell', label: 'Оплаты' }, { value: 'sell_refund', label: 'Возвраты' } ]"
              :value.sync="filters.reestr_type"
            />
          </div>
        </CCol>

        <CCol
          xs="12"
          xl="4"
        >
          <div
            class="filters__filter"
            :class="{
              'filters__filter--active':
                filters.created_at_from !== '' || filters.created_at_to !== ''
            }"
          >
            <CRow>
              <CCol col="12">
                <label for="createdAtFrom">Дата создания</label>
              </CCol>
            </CRow>
            <CRow
              align-vertical="center"
              :gutters="false"
            >
              <CCol>
                <v-date-picker
                  v-model="filters.created_at_from"
                  :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                  locale="ru"
                  @input="
                    filters.created_at_from =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                >
                  <template #default="{ inputValue, togglePopover }">
                    <CRow
                      align-vertical="center"
                      :gutters="false"
                    >
                      <CCol>
                        <CInput
                          id="createdAtFrom"
                          :lazy="800"
                          :readonly="true"
                          :value="inputValue"
                          @click="togglePopover({ placement: 'bottom-start' })"
                          @update:value="filters.created_at_from = formatDate($event)"
                        >
                          <template #prepend-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </CCol>
                      <CCol
                        v-if="filters.created_at_from !== ''"
                        col="1"
                        class="text-center"
                      >
                        <CLink @click="filters.created_at_from = ''">
                          <CIcon name="cil-x" />
                        </CLink>
                      </CCol>
                    </CRow>
                  </template>
                </v-date-picker>
              </CCol>
              <CCol
                col="auto"
                class="pl-1 pr-1 text-center"
              >
                &mdash;
              </CCol>
              <CCol>
                <v-date-picker
                  v-model="filters.created_at_to"
                  :attributes="[{ dot: true, dates: new Date(), key: 'today' }]"
                  locale="ru"
                  @input="
                    filters.created_at_to =
                      $event
                        ? locale.format($event, 'YYYY-MM-DD')
                        : null
                  "
                >
                  <template #default="{ inputValue, togglePopover }">
                    <CRow
                      align-vertical="center"
                      :gutters="false"
                    >
                      <CCol>
                        <CInput
                          id="createdAtTo"
                          :lazy="800"
                          :readonly="true"
                          :value="inputValue"
                          @click="togglePopover({ placement: 'bottom-start' })"
                          @update:value="filters.created_at_to = formatDate($event)"
                        >
                          <template #prepend-content>
                            <CIcon name="cil-calendar" />
                          </template>
                        </CInput>
                      </CCol>
                      <CCol
                        v-if="filters.created_at_to !== ''"
                        col="1"
                        class="text-center"
                      >
                        <CLink @click="filters.created_at_to = ''">
                          <CIcon name="cil-x" />
                        </CLink>
                      </CCol>
                    </CRow>
                  </template>
                </v-date-picker>
              </CCol>
            </CRow>
          </div>
        </CCol>
      </CRow>
    </CForm>
    <CDataTable
      class="mt-5"
      :fields="tableFields"
      :header="true"
      :hover="true"
      :items="reestrs"
      :loading="loadingReestrs"
      :striped="true"
    >
      <template #id="{ item }">
        <td class="align-middle text-center">
          <CLink
            :href="getUrl(item.id, 'all')"
            title="Выбрать все чеки по реестру"
          >
            {{ item.id }}
          </CLink>
        </td>
      </template>
      <template #createdAt="{ item }">
        <td class="align-middle text-center">
          {{ getLocalizedDate(item.createdAt) }} {{ getLocalizedTime(item.createdAt) }}
        </td>
      </template>
      <template #closedDateAt="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.closedDateAt !== null">
            {{ getLocalizedDate(item.closedDateAt) }} {{ getLocalizedTime(item.closedDateAt) }}
          </template>
          <template v-else>
            &mdash;
          </template>
        </td>
      </template>
      <template #isCompleted="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.status">
            <template v-if="item.countIsError > 0">
              <span style="color: orange" title="Есть чеки с ошибками">
                <CIcon name="cil-check" />
              </span>
            </template>
            <template v-else>
              <span style="color: green">
                <CIcon name="cil-check"  title="Готово" />
              </span>
            </template>
          </template>
          <template v-else>
            <CIcon name="cil-sync" title="В обработке" />
          </template>
        </td>
      </template>
      <template #reestrType="{ item }">
        <td :class="getColoredClass('align-middle text-center', item.reestrType)">
          {{ item.reestrType === 'sell' ? 'Оплаты' : 'Возвраты' }}
        </td>
      </template>
      <template #countItems="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.countItems > 0">
            <CLink
              :href="getUrl(item.id, 'all')"
              title="Выбрать все чеки по реестру"
            >
              {{ item.countItems }}
            </CLink>
          </template>
          <template v-else>
            {{ item.countItems }}
          </template>

        </td>
      </template>
      <template #countIsWaitingOk="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.countIsWaitingOk > 0">
            <CLink
              :href="getUrl(item.id, 'wait')"
              title="Выбрать чеки по реестру"
            >
              {{ item.countIsWaitingOk }}
            </CLink>
          </template>
          <template v-else>
            {{ item.countIsWaitingOk }}
          </template>
        </td>
      </template>
      <template #countIsWaitingEkam="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.countIsWaitingEkam > 0">
            <CLink
              :href="getUrl(item.id, 'pending')"
              title="Выбрать чеки по реестру"
            >
            {{ item.countIsWaitingEkam }}
            </CLink>
          </template>
          <template v-else>
            {{ item.countIsWaitingEkam }}
          </template>
        </td>
      </template>
      <template #countIsPrinted="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.countIsPrinted > 0">
            <CLink
              :href="getUrl(item.id, 'printed')"
              title="Выбрать чеки по реестру"
            >
              {{ item.countIsPrinted }}
            </CLink>
          </template>
          <template v-else>
            {{ item.countIsPrinted }}
          </template>
        </td>
      </template>
      <template #countIsSendedSms="{ item }">
        <td class="align-middle text-center">
          {{ item.countIsSendedSms }}
        </td>
      </template>
      <template #countIsError="{ item }">
        <td class="align-middle text-center">
          <template v-if="item.countIsError > 0">
            <CLink
              :href="getUrl(item.id, 'error')"
              title="Выбрать чеки по реестру"
            >
              {{ item.countIsError }}
            </CLink>
          </template>
          <template v-else>
            {{ item.countIsError }}
          </template>
        </td>
      </template>
      <template #loading>
        <CElementCover
          :opacity="0.8"
        >
          <CSpinner
            class="custom-spinner"
            color="info"
          />
        </CElementCover>
      </template>
      <template #no-items-view>
        <div class="text-center">
          Отсутствуют реестры
        </div>
      </template>
    </CDataTable>
    <CPagination
      v-if="pagesCount > 1"
      :active-page.sync="currentPage"
      :pages="pagesCount"
      align="start"
      @update:activePage="getReestrs"
    />
    <div class="export-request-completed-alert">
      <CAlert
        color="danger"
        closeButton
        :show.sync="dangerAlertCounter"
        class="position-sticky"
        @close="successAlert"
      >
        {{dangerAlertContent}}
        <CProgress
          :max="10"
          :value="dangerAlertCounter"
          height="3px"
          color="danger"
          hidden
        />
      </CAlert>
      <CAlert
        color="success"
        closeButton
        :show.sync="successAlertCounter"
        class="position-sticky"
      >
        {{successAlertContent}}
        <CProgress
          :max="10"
          :value="successAlertCounter"
          height="3px"
          color="success"
          hidden
        />
      </CAlert>
    </div>
  </CCard>
</template>

<script>
import Swal from 'sweetalert2';
import AmountMixins from '../../../mixins/AmountMixins';
import DateTimeMixins from '../../../mixins/DateTimeMixins';
import ListMixins from '../../../mixins/ListMixins';
import NumberMixins from '../../../mixins/NumberMixins';
import urls from '../../../router/urls';

export default {
  name: 'List',

  mixins: [
    AmountMixins,
    DateTimeMixins,
    ListMixins,
    NumberMixins,
  ],

  data() {
    return {
      actionIsBeingPerformed: false,
      checkedReestrs: [],
      filters: {},
      loadingReestr: false,
      loadingReestrs: false,
      loadingProgressBar: false,
      progressBarValue: 0,
      dangerAlertCounter: 0,
      successAlertCounter: 0,
      dangerAlertContent: '',
      successAlertContent: '',
      isShowLoadingForm: false,
      timerId: undefined,
      reestrs: [],
      performAndActionWithAll: false,
      tableFields: [
        // {
        //   _classes: ['align-middle', 'text-center'],
        //   key: 'checkReestr',
        //   label: '',
        // },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'id',
          label: '№',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'createdAt',
          label: 'Дата загрузки',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'reestrType',
          label: 'Тип',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'closedDateAt',
          label: 'Дата закрытия',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'isCompleted',
          label: 'Статус',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countItems',
          label: 'Всего',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countIsWaitingOk',
          label: 'В очереди ОК',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countIsWaitingEkam',
          label: 'В очереди ЕКАМ',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countIsPrinted',
          label: 'Оформлен',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countIsSendedSms',
          label: 'Отправлено SMS',
        },
        {
          _classes: ['align-middle', 'text-center'],
          key: 'countIsError',
          label: 'Ошибка',
        },
      ],
      total: {
        reestrs: 0,
      },
    };
  },

  created() {
    this.initFilters();
    this.setFiltersFromUrl();

    this.getReestrs();

    this.timerId = setInterval(this.getLoadingProgress, 10000);
  },
  methods: {
    async clearFilters() {
      this.initFilters();

      await this.find();
    },
    async find() {
      this.checkedReestrs = [];
      this.currentPage = 1;

      await this.getReestrs();
    },
    async getReestrs() {
      this.loadingReestrs = true;

      try {
        const queryString = this.getFiltersAsQueryString();

        const { data } = await this.$axios.get(urls.urlGetReestrs + queryString);

        this.currentPage = data.metadata.page_num;

        this.reestrs = [];

        data.reestrs.forEach(({
          id: id,
          created_at: createdAt,
          closed_date_at: closedDateAt,
          status: status,
          reestr_type: reestrType,
          count_items: countItems,
          count_is_waiting_ok: countIsWaitingOk,
          count_is_waiting_ekam: countIsWaitingEkam,
          count_is_printed: countIsPrinted,
          count_is_sended_sms: countIsSendedSms,
          count_is_error: countIsError
        }) => {
          this.reestrs.push({
            id,
            createdAt,
            closedDateAt,
            status,
            reestrType,
            countItems,
            countIsWaitingOk,
            countIsWaitingEkam,
            countIsPrinted,
            countIsSendedSms,
            countIsError
          });
        });

        this.pagesCount = data.metadata.total_pages;

        this.total.reestrs = data.metadata.total_reestrs;

        const url = `${this.$route.path}${queryString}`;

        window.history.pushState({ url }, '', url);
      } catch (e) {
        if (e.response) {
          this.dangerAlertContent = 'При загрузке списка реестров возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику';
          this.dangerAlertCounter = 10;

        }
      } finally {
        this.loadingReestrs = false;
      }
    },

    async pickFile(reestrType) {
      this.reestrType = reestrType;
      this.$refs.fileElem.click();
    },

    async onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.reestrName = files[0].name;

        if (this.reestrName.lastIndexOf('.') <= 0) {
          return
        }

        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {

          this.loadingReestr = true;
          this.reestrUrl = fr.result;
          this.reestrFile = files[0]; // this is an reestr file that can be sent to server...

          this.timerId = setInterval(this.getLoadingProgress, 10000);

          const data = new FormData(document.getElementById('loadingReestrForm'));
          data.append('file', this.reestrFile);
          data.append('reestrType', this.reestrType);
          axios.post('/api/load-reestr', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(response => {
              this.loadingReestr = false;
              this.loadingProgressBar = true;
              // this.timerId = setInterval(this.getLoadingProgress, 10000);

              this.successAlertContent = response.data.message;
              this.successAlertCounter = 10;

              this.getLoadingProgress();
              this.getReestrs();
            })
            .catch(error => {
              this.dangerAlertContent = 'При загрузке реестра возникла ошибка. Пожалуйста, повторите попытку позднее. При повторении ошибки сообщите разработчику';

              if (error.response.data.message !== undefined) {
                this.dangerAlertContent = error.response.data.message;
              }
              this.dangerAlertCounter = 10;

              console.warn(error.response)

              this.loadingReestr = false;
              this.loadingProgressBar = false;
            })

        })
      } else {
        this.reestrName = '';
        this.reestrFile = '';
        this.reestrUrl  = '';
      }
    },

    getColoredClass(classes, reestrType) {
      return reestrType !== 'sell' ? classes + ' text-color-red' : classes;
    },

    initFilters() {
      this.filters = {
        created_at_from: '',
        created_at_to: '',
        id: '',
      };
    },

    async getLoadingProgress() {
      axios.get('/api/get-loading-progress')
        .then(response => {
          this.loadingProgressBar = false;
          let self = this;
          if (response.data.progressValue !== undefined) {
            if (100 == response.data.progressValue) {
              this.loadingProgressBar = false;
              clearInterval(this.timerId);

              this.successAlertContent = 'Реестр загружен и находится в очереди на отправку чеков';
              this.successAlertCounter = 10;

              this.getReestrs();

            } else if (-1 == response.data.progressValue) { // если ошибка
              this.loadingProgressBar = false;
              clearInterval(this.timerId);

              this.dangerAlertContent = response.data.message;
              this.dangerAlertCounter = 10;

            } else {
              this.loadingProgressBar = true;
              this.progressBarValue = parseInt(response.data.progressValue);
            }
          } else {
            this.loadingProgressBar = false;
            clearInterval(this.timerId);
          }
        })
        .catch(error => {
          console.warn(error);
          clearInterval(this.timerId);
        })
    },

    getUrl(itemId, orderState) {
      let url = '/admin/orders?filters[external_order_id]=' + itemId + '-';
      switch (orderState) {
        case 'wait': url = url + '&filters[status]=wait';
          break;
        case 'pending': url = url + '&filters[status]=pending';
          break;
        case 'printed': url = url + '&filters[status]=printed';
          break;
        case 'error': url = url + '&filters[status]=error';
          break;
      }

      return url;
    },
  },
};
</script>

<style scoped>

</style>
