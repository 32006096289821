import { Locale } from 'v-calendar';

export default {
  data() {
    return {
      currentPage: 1,
      filters: {},
      filtersVisible: false,
      locale: new Locale(),
      pagesCount: 1,
    };
  },

  computed: {
    filtersSet() {
      return this.someObjectValueNotEmpty(this.filters);
    },
  },

  methods: {
    add2SearchParams(searchParams, key, value, path = '') {
      let val;

      if (typeof value === 'object') {
        val = value && Object.entries(value).length > 0 ? value : '';
      } else {
        val = value;
      }

      if (typeof val === 'object') {
        if (Array.isArray(val)) {
          val.forEach((v, k) => {
            this.add2SearchParams(searchParams, k, v, `${path}[${key}]`);
          });
        } else {
          Object.entries(val).forEach(([k, v]) => {
            this.add2SearchParams(searchParams, k, v, `${path}[${key}]`);
          });
        }
      } else if (val !== null && val !== '') {
        searchParams.push(`${path}[${key}]=${val}`);
      }
    },

    formatDate(date) {
      return this.locale.format(this.locale.parse(date, ['DD.MM.YYYY', 'MM/DD/YYYY']), 'YYYY-MM-DD');
    },

    getFiltersAsQueryString() {
      const searchParams = [];

      Object.entries(this.filters).forEach(([key, value]) => {
        this.add2SearchParams(searchParams, key, value);
      });

      let queryString = '';

      if (searchParams.length > 0) {
        queryString += `?filters${searchParams.join('&filters')}`;
      }

      if (this.currentPage > 1) {
        queryString += `${queryString === '' ? '?' : '&'}page=${this.currentPage}`;
      }

      return queryString;
    },

    mergeObjectsDeep(...objects) {
      const isObject = (obj) => obj && typeof obj === 'object';

      return objects.reduce((prev, obj) => {
        Object.keys(obj).forEach((key) => {
          const pVal = prev[key];
          const oVal = obj[key];

          if (Array.isArray(pVal) && Array.isArray(oVal)) {
            // eslint-disable-next-line no-param-reassign
            prev[key] = pVal.concat(...oVal);
          } else if (isObject(pVal) && isObject(oVal)) {
            // eslint-disable-next-line no-param-reassign
            prev[key] = this.mergeObjectsDeep(pVal, oVal);
          } else {
            // eslint-disable-next-line no-param-reassign
            prev[key] = oVal;
          }
        });

        return prev;
      }, {});
    },

    setFiltersFromUrl() {
      Object.entries(this.$route.query).forEach(([key, value]) => {
        let param = {};

        const keys = [...key.matchAll(/\[([^[]+)]/g)];

        for (let i = keys.length - 1; i >= 0; i -= 1) {
          if (i === keys.length - 1) {
            param[keys[i][1]] = value;
          } else {
            const paramAsStr = JSON.stringify(param);

            param = {};

            param[keys[i][1]] = JSON.parse(paramAsStr);
          }
        }

        this.filters = this.mergeObjectsDeep(this.filters, param);
      });
    },

    someObjectValueNotEmpty(object) {
      return Object.values(object).some((value) => {
        if (value && typeof value === 'object') {
          return this.someObjectValueNotEmpty(value);
        }

        return value !== null && value !== '';
      });
    },
  },
};
